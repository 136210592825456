<template>
  <div>
    <Tab :list="tabs"></Tab>

    <router-view> </router-view>
  </div>
</template>
<script>
import Tab from "@/components/Tab";
export default {
  components: {
    Tab,
  },
  data() {
    return {
      tabs: [
        {
          title: "园区总览",
          path: "/drainage/overview/overview", //根据router中的path全路径
        },
        {
          title: "监控直播",
          path: "/drainage/overview/monitor",
        },
      ],
    };
  },
  mounted() {},
  methods: {},
};
</script>
